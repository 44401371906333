import React from "react"
import LayoutEn from '../../components/layout.en';
import profileImage from "../../../content/home/kazim-yardimci.jpg"
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../../hooks/use-site-metadata';

const IndexPage = ({location}) => {
    const { siteUrl, defaultImage } = useSiteMetadata();

    return (
        <LayoutEn>
            <GatsbySeo
                title="Varlıktan Veriler"
                titleTemplate="%s - Sufism is the Essence of Religion"
                description="You can find information about Kazım Yardimci's books and other publications and access these publications."
                language="en"
                openGraph={{
                    type: "website",
                    url: `${siteUrl}${location.pathname}`,
                    locale: "en",
                    images: [
                        {
                            url: `${siteUrl}${defaultImage}`,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="content">
                <div className="content-wrap pt-0">
                    <div className="container clearfix topmargin-sm">
                        <div className="row gutter-50">
                            <div className="col-md-8">
                                <div className="heading-block center">
                                    <h3>Our Aim</h3>
                                </div>

                                <p className="text-larger center">Our aim is to spread the historical reality of SUFISM to humanity, using today's technology, just for the love of HUMAN in order to please our God , because, the name of the human (MAN) in reality is "Hazret-i İnsan --- The Exalted, Perfect Human".</p>
                            </div>


                            <div className="col-md-4">
                                <img className="shadow-sm" src={profileImage} alt="Kâzım YARDIMCI"/>
                            </div>

                        </div>


                    </div>

                    <div className="container clearfix topmargin-sm bottommargin-lg">
                        <p>Our other aim is to present you some very important and very striking knowledge which has not been written or heard for 2000 years about two holy people: "Hazrat Jesus Christ, the Messiah and Hazrat Maryam". We believe that Hazrat Abraham is the root, Hazrat Muhammed, Hazrat Jesus (İsa) and Hazrat Moses are the three divine branches of that tree.Their only aim was to continue the belief of "ONE (SOLE) GOD - ALLAH İS ONE" in the world. According to the author of the Existence, all the people who belong to the Religion of Abraham are brothers and sisters. We send our best regards to all the people in the Religion of Abraham and to His Children. The source of the divine and mystical thought is the books of the author. (The Inspirations from the Existence)</p>

                    </div>

                    <div className="container clearfix topmargin-sm bottommargin-lg">
                        <div className="fancy-title title-bottom-border">
                            <h4>Sufism is the Essence - Kernel- of Religion</h4>
                        </div>

                        <p>As it is known, every scholar has interpreted religion according to himself, from his point of view. The problem is to be able to choose the most proper one for the essence of religion. <b>Religious interpretation of the Sufis is the most appropriate for Islam </b>because Sufism is the teaching of the essence of religion. It is not interested in the exterior aspects, rules and canonical laws, shortly in details. Sufism tries to teach Allah, Nature, Man and the realities of them. The beauty, order and wisdom in nature are the reflections of Allah on the nature. Nature is the work of Allah and Man is the greatest work of Him, because he is consisted of a body and Spirit (Mind). He is the most superior of all the worlds because he is the synthesis of the inner and outer worlds. Sufis try to live this knowledge, order, wisdom and beauties in nature and see the physical and spiritual beauties in human and learn the divine realities of Him.</p>
                        <p><b>“He who knows himself (his essence) knows God.”</b></p>
                        <p>Briefly, <b>Sufism is the Knowledge of Allah, Wisdom and Love. </b></p>
                        <p>Sufism knows Allah as the essence of Man (Human) and nature. This is infinite knowledge, wisdom and infinite love. To know the Absolute Existence is to fall in love with this Absolute Beauty. Sufism does not deal with the Canonical Law and Jurisprudence. A Sufi does not care about the Catechism except enough knowledge for performing the worship. Shariat (Law) and Tariqat (Way) are not his aims or objectives and they are not the essential but only the details, symbols and ways.</p>
                        <p><b>“A Sufi looks for Allah, who has encompassed both the Earth and the Heavens and who is the infinite knowledge, beauty and energy, in human and in his heart.”</b></p>
                        <p>Again, <b>a Sufi would like to learn wisdom (True Knowledge) and divine reality through deep contemplation and to love the Divine Beauty (the Absolute Beauty) through the beauties of human and nature, that is to say, he tries to acquire real love by metaphorical love.</b></p>
                        <p>The result of this is good moral qualities and honesty, which means to consider the benefits of the society more than his own benefits and to be realistic and sincere by abstaining completely from hypocrisy. It is to struggle against his own carnal self and desires but not of the others’, leaving all kinds of fights, arguments, temptations, corruption and intrigue. It is to be merciful, generous and be pleased with the little in this life and also to oppose violence and terror.</p>
                        <p><b>A real Sufi is against formal (disguise) Islam.</b> He does not pay any importance to Madrasa, Tekke (convent) and Mascid (places of worship). He finds reality in himself and seeks Allah in his heart. He always attaches importance to the heart because the real place of worship, the real tekke and mascid (mosque) is the Heart. The most important thing for the heart is the Remembrance (Ziqr) and the Love of Allah (the Remembrance of the heart and the love of the heart). This is not an external remembrance or physical love.</p>
                        <p><b>It is deep contemplation (thinking), remembrance (to remember Allah and never forget Him) and more importantly, to love God and the Perfect Man, who is the great and mysterious work of Him and Adam (Human) and his children and also nature.</b></p>
                        <p>It is to become mature while being immature and to reach divine perfection. All the Sufis have explained this as it is. This humble servant (fakir), has already explained Sufism in details in the books we have written so far, such as: “Varlık (Existence), İslâm’ da Mezhepler ve Yükseliş (The Religious Sects in Islam and Ascending)”, “Muhammed-İsa-Adem (Muhammad-Jesus-Adam)” and in our other books in accordance with the teaching of The Koran- the Divine Message, the Sunnah and the Hadiths of Hz. Muhammad- who is our Divine Messenger, and with Hz. Ali and Hz. Abubakr and with those who follow them.</p>
                        <p>Yunus Emre and Sayyid Ahmed er Rufai Hz. also summarized Sufism, which is a divine sea, as follows:</p>
                        <p>Yunus:</p>
                        <blockquote><p><b>“Shariat and Tariqat is a way for the one who reaches them. </b>
                            <b>Marifat (Divine Wisdom) and Hakiqat (Divine Reality) are in the heart.” </b></p></blockquote>
                        <p>And Sayyid Ahmed er Rufai Hz. said:</p>
                        <blockquote><p>“The heart of a Sufi is lighted with love,<br/>
                            His spiritual heart is broad (comforted).<br/>
                            With the Divine Light of Gnosis”<br/>
                            (His bosom is not depressive)</p></blockquote>
                        <p>We believe in these two precepts and try to find them.</p>
                        <p>See what the Sufi Poet Neyzen Tevfik said about this subject:</p>
                        <blockquote><p>“Sufism stands up to ignorance in Islam<br/>
                            Forget about Rafizi, Alawi, Sunni, all of them<br/>
                            Take offence at all of them,<br/>
                            Mecca, Medina, Karbala, Jerusalem<br/>
                            Their meaning in appearance is ornamentation<br/>
                            I do not care for Masjid, Madrasa, Monastery, and Church, Vatican…”</p></blockquote>
                        <p>(*) Poet Neyzen Tevfik meant to say that the striking appearance of these rituals is ornamentation. However, he indicates that he is respectful to the real meanings of these places.</p>
                        <p >Sources: “Yunus Divanı”, “Onların Alemi” by Sayyid Ahmed er Rufai, 25th hadith</p>
                        <p className="float-right">Kâzım YARDIMCI</p>
                    </div>

                </div>
            </section>
        </LayoutEn>
    )
}

export default IndexPage
